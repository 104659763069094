<template>
  <VNavigationDrawer v-model="drawer" class="bloak-eggnog" app right temporary>
    <BaseButton
      icon
      fab
      small
      color="bloak-blue"
      md-icon="close"
      aria-label="Close Menu"
      @click="$store.commit('nav/TOGGLE_DRAWER')"
    ></BaseButton>
    <VList>
      <VListTile
        v-for="route of routes"
        :key="route.title"
        :to="route.to"
        :href="route.href"
        @click.native.stop="currPageAction"
      >
        <VListTileContent>
          <VListTileTitle
            class="r-headline text-uppercase font-weight-medium bloak-blue--text"
            >{{ route.title }}</VListTileTitle
          >
        </VListTileContent>
      </VListTile>

      <BaseButton
        class="ml-0 mt-3"
        aria-label="Order Takeout"
        href="https://www.toasttab.com/blue-oak-bbq-900-n-carrollton-st/v3/"
        color="bloak-aquarium-gravel"
        large
        >Order Takeout</BaseButton
      >
      <BaseButton
        v-if="theLocation && formattedPhone(theLocation)"
        class="ml-0 mt-3"
        aria-label="Call Us Now"
        :href="`tel:${formattedPhone(theLocation)}`"
        color="bloak-pink"
        large
        >Call Us</BaseButton
      >
    </VList>
  </VNavigationDrawer>
</template>

<script>
import { mapGetters } from 'vuex'

import getContactDetail from '@utils/get-contact-detail'
import formatPhone from '@utils/format-phone'

export default {
  name: 'MobileNavBar',
  components: {},
  data() {
    return {
      showMenusDropdown: false,
      routes: [
        {
          name: 'menu',
          title: 'Menu',
          to: { path: '/locations/blue-oak-bbq' }, // TODO (ESS) : Make this loc slug dynamic
          position: 'center',
        },
        {
          title: 'Catering',
          href: 'https://portal.tripleseat.com/direct_bookings/xw639zsvqfd0',
          exact: true,
          position: 'center',
        },
        {
          title: 'Merchandise',
          href: 'https://shop.blueoakbbq.com/collections/merchandise',
          exact: true,
          position: 'center',
        },
        {
          title: 'Press',
          to: { path: '/press' },
          exact: true,
          position: 'center',
        },
        {
          title: 'Contact & Team',
          to: { path: '/contact' },
          exact: true,
          position: 'center',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('locations', ['getLocationBySlug']),
    drawer: {
      get() {
        return this.$store.state.nav.isDrawerOpen
      },
      set(val) {
        this.$store.commit('nav/SET_DRAWER', val)
      },
    }, // drawer
    theLocation() {
      return this.getLocationBySlug('blue-oak-bbq')
    }, // theLocation
  },
  beforeMount() {
    // Set the drawer to closed on load
    this.$store.commit('nav/SET_DRAWER', false)
  },
  methods: {
    formatPhone,
    formattedPhone(location) {
      if (!location) {
        return false
      }
      return formatPhone(getContactDetail(location, 'phone_number'))
    }, // formattedPhone
    currPageAction() {
      this.$store.commit('nav/SET_DRAWER', false)
    }, // currPageAction
  },
}
</script>

<style lang="scss">
@import '@design';

.nav-drawer-locations {
  a.v-list__tile {
    padding-left: 40px;
  }
}
</style>
